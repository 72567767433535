::selection {
  background: #ffcccc;
}
::-moz-selection {
  background: var(--bg-blue2); /* Gecko Browsers */
}

.App {
  --margin: calc(var(--u) * 5);
  --border: var(--u);
  --trim: calc(2 * (var(--margin) + var(--border)));
  --spacing: 3rem;

  margin: calc(1 * var(--margin)) calc(2 * var(--margin));
  min-height: calc(100vh - var(--trim));
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  background-color: #fff;
  padding: var(--border);
  border-radius: calc(var(--border) * 10);
}

.icon-yellow {
  color: var(--yellow);
}

.icon-blue {
  color: var(--blue);
}

.icon-red {
  color: var(--red);
}

.icon-orange {
  color: var(--orange);
}

header h1 {
  font-size: calc(var(--spacing) * 2);
  margin-block-start: 0;
  margin-block-end: 0;
  word-spacing: calc(var(--spacing) / 30);
  color: var(--dark);
}

header .howdy {
  margin: 0 0 00px 2em;
}

header small {
  color: var(--light-gray);
  font-style: normal;
  font-size: calc(var(--spacing) / 2);
  margin-block-start: calc(var(--spacing) / 10);
  margin-block-end: calc(var(--spacing) / 2);
  display: block;
}

.company {
  font-style: italic;
}

footer {
  font-size: calc(var(--spacing) / 2);
  padding-bottom: calc(var(--spacing) / 6);
  bottom: 0;
  margin-top: auto;
  padding-top: 2em;
}

footer span {
  display: inline;
  margin: 0 auto;
}

hr {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid var(--dark);
  box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px;
}

section {
  margin-block-start: var(--spacing);
}

section.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

section .contact-type {
  margin-block-start: var(--spacing);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-value {
  margin: 1em 0;
  font-style: normal;
  font-family: monospace;
}

article {
  padding: 0 3em;
  font-size: 1.5em;
  max-width: 1280px;
}

.tagline {
  font-size: 1em;
  font-weight: 600;
}
