:root {
  --u: 3px;
  --bg-blue: #243957;
  --bg-blue2: #83a3cc;
  --yellow: #b3934c;
  --blue: #41656c;
  --red: #903c20;
  --orange: #b55725;

  --dark: #303134;
  --gray: #646e78;
  --light-gray: #a1afb0;
  --danger: #8e4d38;
  --info: #b18a70;
  --warning: #daaf4f;
}

body {
  margin: 0;
  font-family: Times, "Times New Roman", Georgia, serif;
  /*  -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
